import type { Media } from "~~/types/strapi/common/schemas-to-ts/Media";

const ORDERED_SIZES = ["large", "medium", "small"];

export function determineImageBreakpoint(image: Media): string {
  const imageFormats = image?.attributes?.formats;
  if (imageFormats === undefined || imageFormats === null) {
    return "";
  }

  const formats = Object.keys(imageFormats);

  if (formats.length === 0) {
    return "";
  }

  const largestFormatFound = ORDERED_SIZES.find((size) => formats.includes(size));

  return largestFormatFound ?? "";
}
